<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <p>Переходим к шарнирам и доработке лица!</p>
            <h3 class="nomargin">Необходимые материалы:</h3>           
            <ul>
                <li>Линейка с круглыми отверстиями</li>
                <li>Стеки</li>
                <li>Надфили, шкурки</li>
                <li>Циркуль, прозрачная линейка с разметкой</li>
            </ul>
            <h3>1. Детализируем лицо по-сухому </h3>
        </div>

        <VideoView video-id="9fd59e33814f4c88947d26eadc95ae8f"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Выглаживаем поврехность верхнего и нижнего века надфилем. Формируем красивую форму глаз. Сырой кистью полируем поверхность.</li>
                <li>Создаем форму ноздрей макетным ножом. Выглаживаем сырой кистью</li>
                <li>Формируем фильтрум носа</li>
                <li>Корректируем форму губ</li>
                <li>Вырезаем ямочки по бокам губ, сглаживаем сырой кистью</li>
                <li>Корректируем форму смыкания губ</li>
            </ol>
            
            <h3>2. Формируем лоб и надбровные дуги</h3>
        </div>

        <VideoView video-id="f9fb2a1eb8284dd1a7d93c73e83e3686"/>

        <div class="MBtextcont">
            <h3>3. Добавляем объем на губах</h3>
        </div>
        
        <VideoView video-id="6c9db46a2052422e89667393e1da6818"/>

        

        <div class="MBtextcont">
            <h3>4. Устанавливаем шарнир в руке</h3>
        </div> 

        <VideoView video-id="c3f39f4aacca4e0e9d5c5e988af3f42a"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Проверяем, чтобы руки были одинаковой длины</li>
                <li>Сошкуриваем срезы сверху и снизу, чтобы они были ровными </li>
                <li>Проверьте толщину стенки и расположение отверстий, они должны быть посередине. </li>
                <li>Верхнее отверстие расширьте в виде чаши + выгладите надфилем</li>
                <li>Находим середину руки, выводим метку на срез. С помощью линейки с круглыми отверстиями размечаем срез. Каждую отметку выводим на боковую часть руки. </li>
                <li>Шкурим срез шарика, размечаем.</li>
                <li>Прикладываем шарик к верхнему срезу руки, смотрим, чтобы все метки совпадали.</li>
                <li>От центра шарики отступаем по 5мм в бок, намечаем линии среза. Макетным ножом срезаем отвесно вниз. Убираем излишки пластика внутри, создавая форму петли. </li>
                <li>Выкладываем на шарик слой пластика и соединяем с рукой.  Все излишки аккуратно примазываем.</li>
                <li>Делаем локоть в форме капли</li>
            </ol>

            <h3>5. Устанавливаем шарнир в ноге</h3>
        </div>
                
        <VideoView video-id="2fb3fa1686844210a87583ddb031d4a6"/>

        <div class="MBtextcont">

            <h3>6. Устанавливаем шарнир в кисти</h3>
        </div>
                
        <VideoView video-id="bcdee3ebdec7493494479da1b4cb50ca"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Размечаем шарик на 8 частей</li>
                <li>Прикладываем кисть к чертежу, отмечаем линию установки шарика. Намечаем центр ладони. Срезаем макетным ножом пластик до отметки под углом 45гр. </li>
                <li>Срезаем попку шарика</li>
                <li>Выкладываем слой пластика на кисть и соединяем с шариком. Следим, чтобы центр руки совпадал с центром шарика! Излишки пластика аккуратно примазываем.</li>
                <li>Проверяем кисть по чертежу</li>
            </ol>

            <h3>7. Устанавливаем шарнир в стопе</h3>
        </div>
                
        <VideoView video-id="95f5d116dc3f4d44813e3cac78c1189c"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Размечаем шарик на 8 частей</li>
                <li>Прикладываем стопу к чертежу сбоку и отмечаем центр. Также намечаем центр стопы спереди.</li>
                <li>Срезаем попку у шарика, соединяем со стопой и прикладываем к чертежу, смотрим, сколько нужно срезать пластика (на стопе), чтобы шарик встал как надо. </li>
                <li>Выкладываем слой пластика на стопу и соединяем с шариком. Следим, чтобы все линии на стопе совпадали с линиями на шарике! Излишки пластика аккуратно примазываем.</li>
                <li>Проверяем стопу по чертежу</li>
            </ol>

            <h3>8. Дорабатываем объемы лица</h3>
        </div>
                
        <VideoView video-id="65f1a2720f5243e692bdafba6ea13c9a"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Нарабатываем объем века сверху</li>
                <li>Если нужно, добавляем объем на переносицу</li>
                <li>Добавляем скулы</li>
                <li>Проверьте, чтобы рельеф носа плавно переходил в щечку</li>
                <li>Добавляем щечку</li>
                <li>Соединяем голову</li>
            </ol>

            <a href="/files/BodoirV2/Head2.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-head2.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3>9. Устанавливаем шарнир в шее</h3>
        </div>
                
        <VideoView video-id="0eae2f29f8ff4f599218b758cff18135"/>

        <div class="MBtextcont">
            <p class="nomargin"><b>Что нужно сделать:</b></p>
            <ol>
                <li>Определяемся с длиной и шириной шеи</li>
                <li>Сошкурим срезы у шеи и шарика, чтобы они были прямыми</li>
                <li>Размечаем полусферу</li>
                <li>Находим середину тела, переноси на срез и также размечаем</li>
                <li>Выкладываем слой пластика и соединяем, все излишки примазываем</li>
                <li>Если нужно дорабатываем по толщине</li>
            </ol>

            <a href="/files/BodoirV2/Balls.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/membersh/bodoirV2/checklist-button-balls.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист в картинках</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>

            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Установить шарниры в ногах, руках, стопах, кистях, шее</li>
                <li>Долепить лицо, соединить голову</li>
            </ol>
           <h3>Время выполнения: 5 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>